import React, { useContext } from 'react';
import { PhotoshopPicker } from 'react-color';
import ColorPickerWrapper from './ColorPickerWrapper';
import languageContext from '../../../context/languageContext';

const ColorPicker = ({ active, dialogColor, loccolor, handleComplete }: any) => {
    const language = useContext<any>(languageContext);

    const title = active === 'mainColor' ? language?.m_h_as_select_main_color : language?.m_h_as_accent_color;

    return (
        <aside className="pfc-dialog pfc-dialog_medium cc-dialog color-picker">
            <div className="modal-inner-wrap">
                <div id="modal-content-15" className="modal-content" data-role="content">
                    <div id="my-modal" data-role="modal" className="modal-content-wrap">
                        <div data-role="header">
                            <button
                                className="action-close"
                                data-role="closeBtn"
                                type="button"
                                onClick={() => dialogColor(false, '')}
                            >
                                <span>Close</span>
                            </button>
                        </div>
                        <div data-role="content">
                            <h2>{title}</h2>
                            <div id="modal-content" className="modal-content-inner">
                                <ColorPickerWrapper loccolor={loccolor} onChangeComplete={handleComplete}>
                                    <PhotoshopPicker color={loccolor} onChangeComplete={handleComplete} />
                                </ColorPickerWrapper>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="modal-footer">
                    <button
                        className="action tertiary btn-icon btn-medium"
                        type="button"
                        data-role="action"
                        onClick={() => dialogColor(false, '')}
                    >
                        <i className=" icon pfc-arrow-right"></i>
                        <span>{language?.cancel}</span>
                    </button>
                    <button
                        className="action primary btn-medium"
                        type="button"
                        data-role="action"
                        onClick={() => dialogColor(false, '', true)}
                    >
                        <span>{language?.ok}</span>
                    </button>
                </footer>
            </div>
        </aside>
    );
};
export default ColorPicker;
