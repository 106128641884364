import React, { useState, useContext, useRef, useEffect } from 'react';
import languageContext from '../../../context/languageContext';
import boardContext from '../../../context/BoardContext';

export default function PTInput({
    title = '',
    element,
    onSlideUpdate,
    cssStyle = '',
    style = {},
    fallbackText = false,
    limit = 0,
    mode = true,
}: any) {
    const { _id = 0, draw_value = '', text_align, element_id, allow_translation = false } = element;
    // font_size = 10, font_name, font_weight,
    const lang: any = useContext(languageContext);
    const { look }: any = useContext(boardContext);
    const { font_color = '#ffffff' } = look;

    const [edit, setEdit] = useState(mode);
    const langValue = lang !== undefined ? lang[element_id] : draw_value;
    const val = allow_translation === true ? langValue : draw_value;
    const [value, setvalue] = useState(val);
    const inputRef: any = useRef(null);
    const displayRef: any = useRef(null);

    useEffect(() => {
        if (edit !== true) inputRef.current.focus();
    }, [edit]);
    useEffect(() => {
        if (edit === false) inputRef.current.focus();
        if (element.group === 'user_content' && displayRef.current !== null) {
            displayRef.current.style.outline = `1px dashed ${font_color}`;
        }
    }, [edit]);

    const handleMouseOver = () => {
        if (displayRef.current !== null) {
            displayRef.current.style.outline = `1px dashed ${font_color}`;
        }
    };
    const handleMouseOut = () => {
        if (element.group !== 'user_content' && displayRef.current !== null) {
            displayRef.current.style.outline = '';
        }
    };

    const inputStyle = {
        // fontSize: `${font_size}px`,
        // fontWeight: `${font_weight}`,
        // fontFamily: font_name || '',
        // lineHeight: '16px',
        // background: 'none',
        // color: '#fff',
        textAlign: text_align,
        ...style,
    };

    function toggleEdit(flag: boolean) {
        setEdit(flag);
        // setTimeout(function () {
        //     // const ele = document.getElementById('texttext');
        //     // ele?.focus();
        // }, 200)
    }

    function onLeave() {
        setTimeout(function () {
            toggleEdit(true);
        }, 100);
        /**
         * Condition that value is different
         * make API call
         */
        if (draw_value !== value) {
            /*
             * After editing translated label/ text
             * edited text should be displayed
             * for that allow_translation to false
             */
            if (allow_translation === true) {
                onSlideUpdate([
                    { id: _id, type: 'allow_translation', value: false },
                    { id: _id, type: 'draw_value', value },
                ]);
            } else {
                onSlideUpdate({ id: _id, type: 'draw_value', value });
            }
        }
    }

    function focushere(e: any) {
        if (draw_value !== null) {
            e.target.selectionStart = value.length;
            e.target.selectionEnd = `${value.length}`;
        }
    }

    function handleChange(e: any) {
        if (limit !== 0) {
            if (e.target.value.length > limit) {
                alert("You can't add more text than this.");
                return null;
            }
        }
        setvalue(e.target.value);
    }

    return (
        <>
            {edit === true ? (
                <>
                    <span
                        ref={displayRef}
                        title={title}
                        role="button"
                        className={`hoverFormELe ${cssStyle}`}
                        style={inputStyle}
                        onClick={() => {
                            toggleEdit(false);
                        }}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                    >
                        {fallbackText === false ? value : value === '' ? '---' : value}
                    </span>
                </>
            ) : (
                <input
                    ref={inputRef}
                    type="text"
                    id="texttext"
                    name="texttext"
                    className={`${cssStyle} text-line-height hover_Form_ELe`}
                    style={{ ...style, outline: `1px dashed ${font_color}`, background: 'none' }}
                    value={value}
                    onBlur={onLeave}
                    onFocus={focushere}
                    onChange={handleChange}
                />
            )}
        </>
    );
}
