import React, { useState, useEffect } from 'react';
import './tool.css';
// Constant
import { CORPORATE_COLOR, FRONT_COVER, BACK_COVER, COVER_IMAGE } from './constant';
// APIs
import { getAllAdminSetting } from '../../api/adminSetting/getAdminSetting';
import { putUpdateStatus } from '../../api/adminSetting/putUpdateStatus';
import { postSaveSetting } from '../../api/adminSetting/postSaveSetting';
import { putSaveSetting } from '../../api/adminSetting/putSaveSetting';
import { postUploadImage } from '../../api/adminSetting/postUploadImage';
import { deleteCoverImage } from '../../api/adminSetting/deleteCoverImage';
// Components
import MessageDialog from './Dialog/MessageDialog';
import ColorPicker from './ColorPicker/ColorPicker';
import Upload from './Upload/Upload';

import { IScheme, IColorSavePayload, IColorPickComplete, IUpload, IDelete } from './type';
import languageContext from '../../context/languageContext';

const Tool = (props: any) => {
    const { validationToken } = props;
    const defaultColor = '#cccccc';
    // const msgMaincolor = 'Main colour must be selected';
    // const msgCommon = 'Implement Main color in remaining accent color.';

    const [isLoading, setLoading] = useState(0);
    const [colorFlag, setColorFlag] = useState(false);
    const [coverFlag, setCoverFlag] = useState(false);
    const [scheme, setScheme] = useState<IScheme | any>({
        _id: null,
        mainColor: '',
        accent1: '',
        accent2: '',
    });
    const [cover, setCover] = useState({});
    const [change, setChange] = useState(true);

    const [dialog, setDialog] = useState(false);
    // const [dialogMsg, setDialogMsg] = useState(msgCommon);
    const [colorpick, setColorpick] = useState(false);

    /* new work */
    const [loccolor, setLoccolor] = useState(defaultColor);
    const [colorCount, setColorCount] = useState(0);
    const [active, setActive] = useState('');

    const [language, setLanguage] = useState<any | null>(null);

    useEffect(() => {
        const { baseUrl, clientID, extraKey } = props;
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            setLoading((o: number) => o + 1);
            getAllAdminSetting(baseUrl, clientID, extraKey, accesskey, {
                setColorFlag: setColorFlag,
                setCoverFlag: setCoverFlag,
                setScheme,
                setCover,
                setLanguage,
                setLoading,
            });
        });
        const target = document.getElementById('tool-bg') as HTMLDivElement;
        const target2 = document.getElementById('presentation_container') as HTMLDivElement;
        if (target2 !== null) {
            target2.style.background = 'none';
        }
        if (target !== null) {
            target.style.background = 'none';
        }
        return () => {
            if (target !== null) target.style.background = '';
            if (target2 !== null) target2.style.background = '';
        };
    }, []);

    const handleToggle = (event: any, set: any) => {
        const { name, checked } = event.target;

        const { baseUrl, clientID, extraKey } = props;
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            putUpdateStatus(
                baseUrl,
                clientID,
                extraKey,
                accesskey,
                {
                    status: checked,
                    type: name,
                },
                set
            );
        });
    };

    const colorSaveValidation = () => {
        /* if (scheme.mainColor === '') {
            // setDialogMsg(msgMaincolor);
            setDialog(true);
            return null;
        } */
        const validation = Object.keys(scheme).some((k: string) => {
            return scheme[k] === '';
        });
        if (validation === true) {
            // setDialogMsg(msgCommon);
            setDialog(true);
        } else {
            // const { mainColor, accent1, accent2 } = scheme;
            colorSave({ ...scheme });
        }
        document.body.style.overflow = 'hidden';
    };

    const colorSave = (colors: IColorSavePayload) => {
        setChange(true);
        const { baseUrl, clientID, extraKey } = props;

        if (scheme._id === null) {
            const accessToken: any = validationToken(props.type);
            accessToken.then(({ accesskey }: any) => {
                postSaveSetting(
                    baseUrl,
                    clientID,
                    extraKey,
                    accesskey,
                    {
                        ...colors,
                        type: CORPORATE_COLOR,
                    },
                    setScheme
                );
            });
        } else {
            const accessToken: any = validationToken(props.type);
            accessToken.then(({ accesskey }: any) => {
                putSaveSetting(
                    baseUrl,
                    clientID,
                    extraKey,
                    accesskey,
                    {
                        _id: scheme._id,
                        ...colors,
                        type: CORPORATE_COLOR,
                    },
                    setScheme
                );
            });
        }
    };

    const dialogAction = (flag: boolean) => {
        if (flag === true) {
            const { mainColor, accent1, accent2 } = scheme;

            if (mainColor !== '') {
                colorSave({
                    mainColor,
                    accent1: accent1 !== '' ? accent1 : mainColor,
                    accent2: accent2 !== '' ? accent2 : mainColor,
                });
                document.body.style.overflow = 'auto';
                setDialog(false);
                return null;
            }
            if (accent1 !== '') {
                colorSave({
                    mainColor: mainColor !== '' ? mainColor : accent1,
                    accent1,
                    accent2: accent2 !== '' ? accent2 : accent1,
                });
                document.body.style.overflow = 'auto';
                setDialog(false);
                return null;
            }
            if (accent2 !== '') {
                colorSave({
                    mainColor: mainColor !== '' ? mainColor : accent2,
                    accent1: accent1 !== '' ? accent1 : accent2,
                    accent2,
                });
                document.body.style.overflow = 'auto';
                setDialog(false);
            }
        }
        /* if (flag === true && scheme.mainColor !== '') {
            const { mainColor } = scheme;
            let { accent1, accent2 } = scheme;
            accent1 = accent1 !== '' ? accent1 : mainColor;
            accent2 = accent2 !== '' ? accent2 : mainColor;

            colorSave({ mainColor, accent1, accent2 });
        }
        */
        document.body.style.overflow = 'auto';
        setDialog(false);
    };

    const dialogColor = (flag: boolean, name: string, action = false) => {
        if (flag === false && action === false) {
            setColorpick(flag);
            return null;
        }

        if (name !== '') {
            setActive(name);
            const color = scheme[name] as any;
            const n = color !== '' ? color : defaultColor;
            // setChange(false);
            setLoccolor(n);
        }

        if (action === true) {
            setScheme((o: any) => ({ ...o, [active]: loccolor }));
            // setActive('');
            setColorCount(1);
            setChange(false);
            if (scheme.mainColor === '' && active !== 'mainColor' && colorCount === 0) {
                setDialog(true);
            }
        }

        setColorpick(flag);
        // console.log('====', active, loccolor, '--', flag, name, color);
    };

    const handleComplete = ({ hex = '' }: IColorPickComplete) => {
        if (hex !== '') setLoccolor(hex);
    };

    const handleUpload = (payload: IUpload) => {
        const { baseUrl, clientID, extraKey } = props;
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            setLoading((o: number) => o + 1);
            postUploadImage(
                baseUrl,
                clientID,
                extraKey,
                accesskey,
                { ...payload },
                {
                    setCover,
                    setLoading,
                }
            );
        });
    };

    const handleDelete = (payload: IDelete) => {
        const { baseUrl, clientID, extraKey } = props;
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            setLoading((o: number) => o + 1);
            deleteCoverImage(
                baseUrl,
                clientID,
                extraKey,
                accesskey,
                { settingId: payload._id, type: payload.type },
                { setCover, setLoading }
            );
        });
    };

    if (language === null) return null;

    return (
        <>
            <languageContext.Provider value={{ ...language }}>
                <div className="bg-white">
                    <div>
                        <div className="flex-row mb20">
                            <div className="flex-col-xs-12 flex-col-sm-12 flex-col-md-12 flex-col-lg-12 flex-col-xl-12">
                                <h3 className="fw-450 fz39 lh46 mb5 gotham-black">
                                    {language.as_title !== undefined ? language.as_title : 'emp'}
                                </h3>
                                <p className="fz16 fw-325 lh24 gotham-book">{language?.as_title_desc}</p>
                            </div>
                        </div>
                        <div className=" pfc-divider pfc-divider_default mt30 mb30"></div>
                        <div className="flex-row">
                            <div className="flex-col-xs-4 flex-col-sm-4 flex-col-md-4 flex-col-lg-4 flex-col-xl-4 fz14">
                                <div className="pfc-toggle mb10">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={colorFlag}
                                            name={CORPORATE_COLOR}
                                            onChange={(event: any) => handleToggle(event, setColorFlag)}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                    <span className="label fz19 lh22 fw-400 gotham-black">{language?.as_color}</span>
                                </div>
                                <span className="fz14 lh21 fw-325 gotham-book">{language?.as_color_desc}</span>
                            </div>
                            <div className="flex-col-xs-8 flex-col-sm-8 flex-col-md-8 flex-col-lg-8 flex-col-xl-8">
                                <div className={`cc-tborder ${colorFlag === true ? '' : 'layer'}`}>
                                    <div className={`flex justify-space-between align-center pr20 relative `}>
                                        <div>
                                            <div className="flex">
                                                <div>
                                                    <div className="cc-label py10 fz16 lh16 fw-350 gotham-medium">
                                                        {language?.main}
                                                    </div>
                                                    <div
                                                        className="main-colour"
                                                        style={{
                                                            background:
                                                                scheme.mainColor !== ''
                                                                    ? scheme.mainColor
                                                                    : defaultColor,
                                                        }}
                                                        onClick={() => dialogColor(true, 'mainColor')}
                                                    ></div>
                                                </div>
                                                <div className="vline" />
                                                <div>
                                                    <div className="cc-label py10 fz16 lh16 fw-350 gotham-medium">
                                                        {language?.pillow_accent_colour}
                                                    </div>
                                                    <div className="flex">
                                                        <div
                                                            className="mr15 accent-colour"
                                                            style={{
                                                                background:
                                                                    scheme.accent1 !== ''
                                                                        ? scheme.accent1
                                                                        : defaultColor,
                                                            }}
                                                            onClick={() => dialogColor(true, 'accent1')}
                                                        ></div>
                                                        <div
                                                            className="accent-colour"
                                                            style={{
                                                                background:
                                                                    scheme.accent2 !== ''
                                                                        ? scheme.accent2
                                                                        : defaultColor,
                                                            }}
                                                            onClick={() => dialogColor(true, 'accent2')}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                className="action secondary mt10"
                                                onClick={() => colorSaveValidation()}
                                                disabled={change}
                                            >
                                                {language?.btn_save}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" pfc-divider pfc-divider_default mt30 mb30"></div>
                        <div className="flex-row mb20">
                            <div className="flex-col-xs-4 flex-col-sm-4 flex-col-md-4 flex-col-lg-4 flex-col-xl-4 fz14">
                                <div className="pfc-toggle mb10">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={coverFlag}
                                            name={COVER_IMAGE}
                                            onChange={(event: any) => handleToggle(event, setCoverFlag)}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                    <span className="label fz19 lh22 fw-400 gotham-black">{language?.as_cover}</span>
                                </div>
                                <span className="fz14 lh21 fw-325 gotham-book">{language?.as_cover_desc}</span>
                            </div>
                            <div className="flex-col-xs-8 flex-col-sm-8 flex-col-md-8 flex-col-lg-8 flex-col-xl-8">
                                <div className={`cc-tborder m-b20 ${coverFlag === true ? '' : 'layer'}`}>
                                    <div className={`relative `}>
                                        <Upload
                                            title={language?.h_front}
                                            id={FRONT_COVER}
                                            f={handleUpload}
                                            data={{ ...cover }}
                                            onDelete={handleDelete}
                                        />
                                        <Upload
                                            title={language?.h_back}
                                            id={BACK_COVER}
                                            f={handleUpload}
                                            data={cover}
                                            onDelete={handleDelete}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {dialog === true && (
                    <MessageDialog
                        dialogAction={dialogAction}
                        dialogMsg={language?.m_e_select_color}
                        btnCancel={true}
                    />
                )}

                {colorpick === true && (
                    <ColorPicker
                        active={active}
                        dialogColor={dialogColor}
                        loccolor={loccolor}
                        handleComplete={handleComplete}
                    />
                )}
            </languageContext.Provider>
            {isLoading !== 0 && (
                <div className="loaderWrapper">
                    <div className="loader"></div>
                </div>
            )}
        </>
    );
};
export default Tool;
